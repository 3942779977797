import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/products.css";
import { tdAssetCareTexts, tdTestTexts } from "../texts/texts";

import maximoimg3 from "../assets/maximo2.png";
import maximoimg4 from "../assets/maximo3.png";

const areas = [
  {
    title: tdAssetCareTexts.header,
    description: tdAssetCareTexts.intro,
    redirectPath: "/tdassetcare",
    image: maximoimg3,
  },
  {
    title: tdTestTexts.hero.header,
    description: tdTestTexts.hero.description,
    redirectPath: "/tdtest",
    image: maximoimg4,
  },
];

const LargeCardsSection = () => {
  const navigate = useNavigate();

  const handleRedirect = (redirectPath: string) => {
    navigate(redirectPath);
  };

  return (
    <section className="large-cards-section">
      <h2>Produkter</h2>
      <div className="cards-container">
        {areas.map((area, index) => (
          <div
            key={index}
            className="large-card"
            onClick={() => handleRedirect(area.redirectPath)}
          >
            <div className="large-card-image">
              <img src={area.image} alt={area.title} />
            </div>
            <div className="large-card-content">
              <h2>{area.title}</h2>
              <p>{area.description}</p>
              <button className="read-more-button">Läs mer</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LargeCardsSection;
