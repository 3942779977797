import React from "react";
import cloud from "../assets/cloud.svg";
import pilar from "../assets/tt_arrows.svg";
import jordglob from "../assets/jordglob.svg";
import plats from "../assets/Plats.svg";
import rails from "../assets/ralisar.svg";
import slalomrails from "../assets/slalomraÌls.svg";
import train from "../assets/nyttalg.svg";
import validering from "../assets/validering.svg";
import services02 from "../assets/services02.svg";
import services06 from "../assets/services06.svg";
import background from "../assets/backgrounds/sthlm22.png";
import Footer from "./Footer";
import { tdAssetCareTexts } from "../texts/texts";
import "../styles/tdassetcare.css";

const TdAssetCarePage = () => {
  return (
    <main className="td-asset-care-page">
      {/* Hero Section */}
      <section className="hero-section-tdasset">
        <img src={background} alt="Stockholm city background" />
        <div className="hero-section-tdasset-text">
          <h1>{tdAssetCareTexts.header}</h1>
          <p>{tdAssetCareTexts.intro}</p>
          <button onClick={() => (window.location.href = "/#kontakta-oss")}>
            {tdAssetCareTexts.buttonText}
          </button>
        </div>
      </section>

      {/* Description Section */}
      <section className="description-section-tdasset">
        <div className="description-intro">
          <h2>{tdAssetCareTexts.reasonsHeader}</h2>
          <ul className="reasons-list">
            {tdAssetCareTexts.reasons.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </ul>
          <p className="description">{tdAssetCareTexts.description}</p>
        </div>

        <div className="description-features">
          <h3>Funktioner</h3>
          <ul className="features-list">
            {tdAssetCareTexts.features.map((feature, index) => (
              <ul key={index}>
                <li>{feature.description}</li>
              </ul>
            ))}
          </ul>
        </div>
      </section>

      {/* Geografiska tjänster */}
      <section className="section-container-tdasset">
        <h2>Geografiska tjänster och funktioner</h2>
        <div className="card-tdasset-grid">
          {tdAssetCareTexts.geoservices.map((service, index) => (
            <div key={index} className="card-tdasset">
              <img
                src={
                  index === 0
                    ? jordglob
                    : index === 1
                    ? slalomrails
                    : index === 2
                    ? plats
                    : rails
                }
                alt={service.title}
              />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Fordonsunderhåll */}
      <section className="section-container-tdasset">
        <h2>Fordonsunderhåll</h2>
        <div className="card-tdasset-grid">
          {tdAssetCareTexts.fordonsunderhall.map((service, index) => (
            <div key={index} className="card-tdasset">
              <img
                src={
                  index === 0
                    ? train
                    : index === 1
                    ? services02
                    : index === 2
                    ? services06
                    : validering
                }
                alt={service.title}
              />
              <h3>{service.title || "Funktion"}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default TdAssetCarePage;
